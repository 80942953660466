import React, { useState } from 'react';


const PlayButton = (props) => {

    const [isHovering, setHovering] = useState(false);

    const handleHover = (truthy) => {
        if (truthy) {
            setHovering(true);
        }
        else {
            setHovering(false);
        }
    }

    return (

        <svg onClick={props.onClick}
            onTouchStart={() => handleHover(true)}
            onTouchEnd={() => handleHover(false)}
            width="126"
            height="126"
            xmlns="http://www.w3.org/2000/svg">
            <g fill="none" fillRule="evenodd">
                <circle
                    fill={"#F7F8FB"}
                    opacity={isHovering ? "0.9 " : ".24"}
                    cx="63"
                    cy="63"
                    r="63" />
                <path
                    d="M72.808 64.645l-15.71 15.368A3 3 0 0 1 52 77.868V47.132a3 3 0 0 1 5.098-2.145l15.71 15.368a3 3 0 0 1 0 4.29z"
                    fill="#18181C" />
            </g>
        </svg>
    )

};

export default PlayButton;