import React from 'react';
import styled from 'styled-components';
import tw from 'tailwind.macro';
import { useStaticQuery } from 'gatsby';

import * as Gs from '../globals/styledComponents.js';

import Layout from '../components/layout';
import Nav from '../components/Nav';
import Row from '../components/Row';
import FeaturedWorkCard from '../components/utils/FeaturedWorkCard.js';
import Footer from '../components/footer.js';
import SEO from '../components/seo';

/*
* Styled Components
*/

const SectionOne = styled.section`
    ${tw`w-full bg-grey-lightest flex flex-wrap items-center`};
    height: 61.8vh;
    border-radius: 0 0 3.236rem 3.236rem;
`
const FeedSection = styled.section`
    ${tw`w-full min-h-screen py-16`};
`

const DropDown = styled.select`
    ${tw`text-dark-grey appearance-none py-6 px-8 bg-transparent outline-none text-gr font-medium`};
    options{
        ${tw`text-dark-grey text-base`};
    }
`


export default function Work(props) {

    const data = useStaticQuery(graphql`
        query{
            work: allContentfulCaseStudyPage{
                edges{
                    node{
                        slug
                        isComingSoon
                        thumbnail: thumbnailImage{
                            fixed(quality: 100){
                                ...GatsbyContentfulFixed_withWebp
                            }
                        }
                        thumbnailVideo{
                            file{
                                url
                            }
                        }
                        thumbnailTitle
                        thumbnailDescription
                        client{
                            logo{
                                file{
                                    url
                                }
                            }
                        }
                    }
                }
            }
        }
    `)

    return (
        <Layout withDarkMenu>
            <SEO
                title="Clearcut Work."
                keywords={[`Clearcut`, `Design`, `Product Design`, 'Product Development', 'Product Marketing', 'Web Design', 'UX Design', 'UI Design', 'React Developers', 'Digital Design Agency', 'Full service creative agency', 'Clearcut Agency']} />
            <SectionOne>
                <Row>

                    <div css={tw`w-full lg:w-1/3`}>
                        <Gs.HeroHeading css={tw`-ml-4 leading-normal`}>
                            Work.
                        </Gs.HeroHeading>
                        <Gs.PreTitle css={tw`m-0`}>
                            Everything we've worked on, or working on, or hope to work on.
                        </Gs.PreTitle>
                    </div>

                </Row>
            </SectionOne>

            <FeedSection>
                {/* <Row justifyBetween>
                    <div css={tw`w-1/3`}>
                        <DropDown>
                            <option value="Everything" selected>Everything</option>
                            <option value="Branding" >Branding</option>
                            <option value="Product" >Product</option>
                        </DropDown>
                    </div>
                </Row> */}

                <Row stretch>
                    <div css={`${tw`w-full flex flex-wrap -mx-4 overflow-hidden py-12 justify-between`}`}>

                        {data.work.edges.map((item, index) =>
                            <>
                                <div
                                    css={`${tw`w-full md:w-1/2 lg:w-1/3 md:px-4 my-10 ${index % 3 == 0 ? 'mt-12' : 'mt-0'}`}`}>
                                    <FeaturedWorkCard
                                        key={index}
                                        title={item.node.title}
                                        to={item.node.slug}
                                        description={item.node.thumbnailDescription}
                                        thumbnail={item.node.thumbnail.fixed}
                                        clientLogo={item.node.client.logo.file.url}
                                        hasThumbnailVideo={item.node.thumbnailVideo !== null}
                                        thumbnailVideoUrl={item.node.thumbnailVideo ? item.node.thumbnailVideo.file.url : ''}
                                        isComingSoon={item.node.isComingSoon}
                                    />
                                </div>
                            </>
                        )}
                    </div>
                </Row>
            </FeedSection>
            <Footer />

        </Layout>
    );
}
